import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import MissingData from "./MissingData"
import axios from "axios"

export default function Drive({  ProjectID ,setAuth}) {
  // const [data, setData] = useState([])
  const [missingData,setMissingData] = useState([])
  const [ticketData,setTicketData] = useState([])


  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: ProjectID,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/Drive_info.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        setMissingData(res.data.MissingData)
        setTicketData(res.data.TicketData)
      })
      .catch(err => console.log("error------>", err))


  }, [])

  return (
    <div>
      <Tabs className="mb-3" justify fill>
        <Tab eventKey="reports" title="TicketData">
        <MissingData Data={ticketData} title={"Ticket"} />
        </Tab>
        <Tab eventKey="tickets" title="MissingData">
          <MissingData Data={missingData} title={"Missing"} />
        </Tab>
      </Tabs>
    </div>
  )
}
