import React, { useState } from "react"
import { Button, Table } from "reactstrap"
import ViewDriveData from "./ViewDriveData"

function MissingData({ Data,title }) {
  const [rowData, setRowData] = useState([])

  const [viewDriveData, setViewDriveData] = useState(false)
  const toggleViewDriveData = () => {
    setViewDriveData(!viewDriveData)
  }

  return (
    <div>
      {
        <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Files</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Data.map((item, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{item.AnswerAt}</td>
                <td>{item.num}</td>
                <td>
                  <Button
                    onClick={() => {
                      setRowData(item[title])
                      toggleViewDriveData()
                    }}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      }
      <ViewDriveData
        rowData={rowData}
        viewDriveData={viewDriveData}
        toggleViewDriveData={toggleViewDriveData}
      />
    </div>
  )
}

export default MissingData
