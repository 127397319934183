export const permissionOptions = [
  { value: "0", label: "NONE" },
  { value: "1", label: "VIEW" },
  { value: "2", label: "VIEW + ADD" },
  { value: "3", label: "VIEW + ADD + EDIT" },
  { value: "4", label: "VIEW + ADD + EDIT + DELETE" },
]

const roles = JSON.parse(localStorage.getItem("roles")) || []
// const roles =[]

const permissions = [
  { R1: "Design Stages" },
  { R2: "Inspection Stages" },
  { R3: "Design Reports" },
  { R4: "Inspection Reports" },
  { R5: "Missing Data" },
  { R6: "System Logs" },
  { R7: "Projects" },
  { R8: "Additional Visits" },
  { R9: "Users" },
  { R10: "User roles" },
  { R11: "Tickets" },
  { R12: "Project Costs" },
  { R13: "Project Quotations" },
  { R14: "RD7 Quotations" },
  { R15: "System Settings" },
  { R16: "DR-spec" },
  { R17: "IM-spec" },
  { R18: "Soil-spec" },
  { R19: "Ticket Moderator" },
  { R20: "RD6 Reviewer" },
  { R21: "RD7" },
  { R22: "QC" },
  { R23: "Str-spec" },
]

export const convertPermission = roles => {
  let userRoles = {}
  roles?.map((item, i) => {
    userRoles = { ...userRoles, ...item }
    userRoles[`R${i + 1}`] = {
      P: item[`R${i + 1}`].split(",")[0],
      G: item[`R${i + 1}`].split(",")[1],
    }
  })

  return userRoles
}

export const userPermissions = convertPermission(roles)
