import React from "react"
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Table,
} from "reactstrap"

function ViewDriveData({ toggleViewDriveData, viewDriveData, rowData }) {
  console.log("rowData: " , rowData)
  return (
    <Modal isOpen={viewDriveData} toggle={toggleViewDriveData} size="xl">
      <ModalHeader></ModalHeader>
      <ModalBody>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>File Name</th>
              <th>Size</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {rowData.map((item,key) => (
              <tr key={key}>
                <td>{key+1}</td>
                <td>{item.File}</td>               
                <td>{item.ID}</td>
                <td>
                  <a
                    href={item.URL}
                    download={item.URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button color="secondary text-underline" size="sm" >
                    <i className="fas fa-download"></i> 

                    </Button>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleViewDriveData}>
          Close
          </Button> 
        {/* <Button color="primary" onClick={toggleViewDriveData}>
          Download All
          </Button>  */}
      </ModalFooter>
    </Modal>
  )
}

export default ViewDriveData
